import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  first,
  from,
  lastValueFrom,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfirmationModalComponent, ModalService, OverlayCloseEvent } from '@dougs/ds';
import {
  EcommerceSale,
  EcommerceSaleCreate,
  EcommerceSalePost,
  EcommerceSales,
  EcommerceSaleSection,
  EcommerceSaleSubSection,
  PackageType,
  SalesChannel,
} from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService, SalesChannelStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';

interface IUpdateEcommerceSale {
  operation: Operation;
  ecommerceSalePost: EcommerceSalePost;
}

@Injectable()
export class OperationEcommerceComponentService {
  private readonly salesChannelSubject: BehaviorSubject<SalesChannel | undefined> = new BehaviorSubject<
    SalesChannel | undefined
  >(undefined);
  public readonly salesChannel$: Observable<SalesChannel | undefined> = this.salesChannelSubject.asObservable();

  private readonly ecommerceOperationSubject: ReplaySubject<Operation> = new ReplaySubject<Operation>(1);

  ecommerceOperation$: Observable<Operation> = this.ecommerceOperationSubject.asObservable();
  salesChannelsByOperation$: Observable<SalesChannel | undefined> = combineLatest([
    this.ecommerceOperation$,
    this.salesChannelState.salesChannels$,
  ]).pipe(
    filter(([operation, _salesChannel]) => !!operation?.metadata?.salesChannelId),
    filter(([_operation, salesChannel]) => !!salesChannel),
    map(([operation, salesChannels]) => {
      const salesChannel: SalesChannel | undefined = salesChannels.find(
        (salesChannel) => salesChannel.id === operation.metadata.salesChannelId,
      );
      this.salesChannelSubject.next(salesChannel);
      return salesChannel;
    }),
  );
  private readonly isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(
    private readonly ecommerceSaleStateService: EcommerceSaleStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly salesChannelState: SalesChannelStateService,
    @Inject(OPERATION_STATE_TOKEN) private readonly abstractOperationsStateService: AbstractOperationsStateService<any>,
  ) {}

  private readonly updateEcommerceSaleSubject: Subject<IUpdateEcommerceSale> = new Subject<IUpdateEcommerceSale>();
  public updateEcommerceSale$: Observable<EcommerceSales | void> = this.updateEcommerceSaleSubject.asObservable().pipe(
    distinctUntilChanged(),
    concatMap(({ operation, ecommerceSalePost }: IUpdateEcommerceSale) =>
      from(this.ecommerceSaleStateService.updateEcommerceSale(operation, ecommerceSalePost)).pipe(
        map((ecommercesSalesUpdated: EcommerceSales | null) => ({ ecommercesSalesUpdated, operation })),
      ),
    ),
    filter(({ ecommercesSalesUpdated }) => !ecommercesSalesUpdated),
    concatMap(({ operation }) => {
      return this.ecommerceSaleStateService.refreshEcommerceSales(operation);
    }),
  );

  private readonly tutorialUrls: { [key: string]: string } = {
    amazoneu: 'https://aide.dougs.fr/fr/articles/8135149-comment-gerer-mes-operations-amazon-avec-dougs',
    wooCommerce: 'https://aide.dougs.fr/fr/articles/9324497-comment-gerer-mes-operations-woocommerce-avec-dougs',
    shopify: 'http://aide.dougs.fr/fr/articles/9324360-comment-gerer-mes-operations-shopify-avec-dougs',
    cdiscount: 'https://aide.dougs.fr/fr/articles/9329644-comment-gerer-les-operations-c-discount-avec-dougs',
    fnac: 'https://aide.dougs.fr/fr/articles/9329600-comment-gerer-mes-operations-fnac-avec-dougs',
    rueDuCommerce: 'https://aide.dougs.fr/fr/articles/9329784-comment-gerer-mes-operations-rue-du-commerce-avec-dougs',
    etsy: 'https://aide.dougs.fr/fr/articles/9334957-comment-gerer-mes-operations-etsy-avec-dougs',
    bolt: 'https://aide.dougs.fr/fr/articles/9356214-comment-gerer-mes-operations-bolt-avec-dougs',
    uber: 'https://aide.dougs.fr/fr/articles/9356253-comment-gerer-mes-operations-uber-avec-dougs',
    heetch: 'https://aide.dougs.fr/fr/articles/9356276-comment-gerer-mes-operations-heetch-avec-dougs',
    booking: 'https://aide.dougs.fr/fr/articles/9374045-comment-gerer-mes-operations-booking-avec-dougs',
    faire: 'https://aide.dougs.fr/fr/articles/9374055-comment-gerer-mes-operations-faire-avec-dougs',
    ebay: 'https://aide.dougs.fr/fr/articles/9374082-comment-gerer-mes-operations-ebay-avec-dougs',
  };

  private readonly supportedSalesChannelTypes: string[] = Object.keys(this.tutorialUrls);

  shouldShowTutorialPanel$: Observable<boolean> = this.salesChannelsByOperation$.pipe(
    filter((salesChannel): salesChannel is SalesChannel => !!salesChannel),
    map((salesChannel: SalesChannel) => {
      return this.supportedSalesChannelTypes.includes(salesChannel.type);
    }),
  );

  tutorialUrl$: Observable<string | undefined> = this.salesChannelsByOperation$.pipe(
    filter((salesChannel): salesChannel is SalesChannel => !!salesChannel),
    map((salesChannel: SalesChannel) => this.tutorialUrls[salesChannel.type]),
  );

  get salesChannel(): SalesChannel | undefined {
    return this.salesChannelSubject.value;
  }

  setEcommerceOperation(operation: Operation): void {
    this.ecommerceOperationSubject.next(operation);
  }

  onAmountChange(
    amount: number,
    sale: EcommerceSale,
    operation: Operation,
    sectionId: string,
    subSectionId: string,
    vatAmount?: number,
  ): void {
    const ecommerceSalePost: EcommerceSalePost = { ...sale, amount, vatAmount, sectionId, subSectionId };
    this.updateEcommerceSaleSubject.next({ operation, ecommerceSalePost });
  }

  async deleteEcommerceSale(
    sale: EcommerceSale,
    operation: Operation,
    sectionId: string,
    subSectionId: string,
  ): Promise<boolean> {
    const ecommerceSalePost: EcommerceSalePost = { ...sale, sectionId, subSectionId };
    return await this.ecommerceSaleStateService.deleteEcommerceSale(operation, ecommerceSalePost);
  }

  async deleteSubSection(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<boolean> {
    const ecommerceSalePost: EcommerceSalePost = {
      sectionId: section.sectionId,
      subSectionId: subSection.subSectionId,
    } as EcommerceSalePost;
    return await this.ecommerceSaleStateService.deleteEcommerceSubSection(operation, ecommerceSalePost);
  }

  async onAddEcommerceSubSection(section: EcommerceSaleSection, operation: Operation): Promise<void> {
    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchOriginLocationClicked(
      section.sectionId,
      operation,
      this.salesChannel,
    );

    const { SelectOriginCountryModalComponent } = await import(
      '../modals/select-origin-country-modal/select-origin-country-modal.component'
    );
    const subSectionId: string | undefined = await lastValueFrom(
      this.modalService
        .open(SelectOriginCountryModalComponent, {
          data: {
            operation,
            salesChannel: this.salesChannel,
          },
        })
        .afterClosed$.pipe(map((result) => (result.data as { subSectionId: string })?.subSectionId)),
    );

    if (!subSectionId) {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchOriginLocationClosed(
        section.sectionId,
        operation,
        this.salesChannel,
      );
      return;
    }

    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchOriginLocationSelected(
      section.sectionId,
      subSectionId,
      operation,
      this.salesChannel,
    );

    const ecommerceSaleCreate: EcommerceSaleCreate | null | undefined = await this.getEcommerceSaleCreate(
      operation,
      section,
      { subSectionId } as EcommerceSaleSubSection,
    );

    if (ecommerceSaleCreate) {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchCountryTaxModalSelected(
        ecommerceSaleCreate.subSectionId,
        ecommerceSaleCreate.sectionId,
        ecommerceSaleCreate.saleId,
        operation,
        this.salesChannel,
      );
      await this.ecommerceSaleStateService.createEcommerceSaleSubSection(operation, ecommerceSaleCreate);
    } else {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchCountryTaxModalClosed(
        subSectionId,
        section.sectionId,
        operation,
        this.salesChannel,
      );
    }
  }

  async onAddEcommerceSale(
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
    operation: Operation,
  ): Promise<void> {
    const ecommerceSaleCreate: EcommerceSaleCreate | null | undefined = await this.getEcommerceSaleCreate(
      operation,
      section,
      subSection,
    );
    if (ecommerceSaleCreate) {
      await this.ecommerceSaleStateService.createEcommerceSale(operation, ecommerceSaleCreate);
    }
  }

  private async getEcommerceSaleCreate(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<EcommerceSaleCreate | null | undefined> {
    const subSectionId: string = subSection.subSectionId;
    const sectionId: string = section.sectionId;
    if (sectionId === 'fees') {
      return await this.selectPaymentProcessor(operation, section, subSection);
    }
    if (subSectionId === 'exonerateSale') {
      return await this.selectVatExemptionReason(operation, section, subSection);
    }
    if (subSectionId === 'ecommerceExpenses') {
      return await this.selectExpenseType(operation, section, subSection);
    }
    if (subSectionId === 'outsideEu') {
      return await this.selectDestinationZoneForIoss(operation, section, subSection);
    }
    return await this.createEcommerceSaleWithDestinationCountry(section, subSection, operation);
  }

  async createEcommerceSaleWithDestinationCountry(
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
    operation: Operation,
  ): Promise<EcommerceSaleCreate | null> {
    const canHavePackageType: boolean =
      (await this.hasIoss()) &&
      !(await this.isMarketplaceDispatch(operation)) &&
      subSection.subSectionId === 'outsideEu';
    const ecommerceSaleCreate: EcommerceSaleCreate | null | undefined = await this.selectDestinationCountryModal(
      operation,
      section,
      subSection,
    );

    if (!ecommerceSaleCreate) {
      return null;
    }

    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchAddSalesRevenueClicked(
      subSection.subSectionId,
      section.sectionId,
      operation,
      this.salesChannel,
    );

    if (canHavePackageType) {
      const packageType: PackageType | undefined = await this.selectPackageTypeIfNecessary(
        operation,
        section,
        subSection,
      );
      if (!packageType) {
        return null;
      }
      ecommerceSaleCreate.packageId = packageType.packageId;
    }
    if (!(await this.hasIoss()) && !canHavePackageType) {
      ecommerceSaleCreate.packageId = 'over150';
    }

    return ecommerceSaleCreate;
  }

  public async selectDestinationCountryModal(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<EcommerceSaleCreate | null | undefined> {
    const { SelectDestinationCountryModalComponent } = await import(
      '../modals/select-destination-country-modal/select-destination-country-modal.component'
    );

    const ecommerceSale: EcommerceSaleCreate | null | undefined = await lastValueFrom(
      this.modalService
        .open(SelectDestinationCountryModalComponent, {
          data: {
            sectionId: section.sectionId,
            subSectionId: subSection.subSectionId,
            operation,
            salesChannel: this.salesChannel,
          },
        })
        .afterClosed$.pipe(
          map((result: OverlayCloseEvent<unknown>) => result.data as EcommerceSaleCreate | null | undefined),
        ),
    );

    if (ecommerceSale?.saleId === 'fr-2.1') {
      return this.selectSpecificVatRateReason(
        operation,
        section.sectionId,
        subSection.subSectionId,
        ecommerceSale?.saleId,
      );
    }

    return ecommerceSale;
  }

  public async selectPackageTypeIfNecessary(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<PackageType | undefined> {
    const { SelectPackageTypeModalComponent } = await import(
      '../modals/select-package-type/select-package-type-component-modal.component'
    );
    const packageType: PackageType = await lastValueFrom(
      this.modalService
        .open(SelectPackageTypeModalComponent, {
          data: operation,
        })
        .afterClosed$.pipe(
          map((result: OverlayCloseEvent<unknown>) => (result.data as { packageType: PackageType })?.packageType),
        ),
    );

    if (operation.metadata.salesChannelId) {
      if (!packageType) {
        this.operationEcommerceMetricsService.trackEcommerceOperationDispatchPackagePriceModalClosed(
          section.sectionId,
          subSection.subSectionId,
          operation,
          this.salesChannel,
        );
      } else {
        this.operationEcommerceMetricsService.trackEcommerceOperationDispatchPackagePriceModalSelected(
          section.sectionId,
          subSection.subSectionId,
          packageType.packageId,
          operation,
          this.salesChannel,
        );
      }
    }

    return packageType;
  }

  public async selectVatExemptionReason(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<EcommerceSaleCreate | null | undefined> {
    const { SelectVatExemptionReasonModalComponent } = await import(
      '../modals/select-vat-exemption-reason-modal/select-vat-exemption-reason-modal.component'
    );
    const vatExemptionReason: EcommerceSaleCreate | null | undefined = await lastValueFrom(
      this.modalService
        .open(SelectVatExemptionReasonModalComponent, {
          data: {
            sectionId: section.sectionId,
            subSectionId: subSection.subSectionId,
            operation,
          },
        })
        .afterClosed$.pipe(
          map((result: OverlayCloseEvent<unknown>) => result.data as EcommerceSaleCreate | null | undefined),
        ),
    );

    if (!vatExemptionReason) {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchExonerationModalClosed(
        section.sectionId,
        subSection.subSectionId,
        operation,
        this.salesChannel,
      );
    } else {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchExonerationSelected(
        section.sectionId,
        subSection.subSectionId,
        vatExemptionReason.saleId,
        operation,
        this.salesChannel,
      );
    }

    return vatExemptionReason;
  }

  private async selectSpecificVatRateReason(
    operation: Operation,
    sectionId: string,
    subSectionId: string,
    saleId: string,
  ): Promise<EcommerceSaleCreate | null | undefined> {
    const { SelectSpecificVatRateReasonModalComponent } = await import(
      '../modals/select-specific-vat-rate-reason-modal/select-specific-vat-rate-reason-modal.component'
    );
    const specificVatRateReason: EcommerceSaleCreate | null | undefined = await lastValueFrom(
      this.modalService
        .open(SelectSpecificVatRateReasonModalComponent, {
          data: {
            sectionId,
            subSectionId,
            saleId,
            operation: operation,
            salesChannel: this.salesChannel,
          },
        })
        .afterClosed$.pipe(
          map((result: OverlayCloseEvent<unknown>) => result.data as EcommerceSaleCreate | null | undefined),
        ),
    );
    return specificVatRateReason;
  }

  public async selectExpenseType(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<EcommerceSaleCreate | null | undefined> {
    const { SelectExpenseTypeModalComponent } = await import(
      '../modals/select-expense-type-modal/select-expense-type-modal.component'
    );

    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchAddExpenseButtonClicked(
      operation,
      this.salesChannel,
    );

    const expenseType: EcommerceSaleCreate | null | undefined = await lastValueFrom(
      this.modalService
        .open(SelectExpenseTypeModalComponent, {
          data: {
            sectionId: section.sectionId,
            subSectionId: subSection.subSectionId,
            operation,
            salesChannel: this.salesChannel,
          },
        })
        .afterClosed$.pipe(
          map((result: OverlayCloseEvent<unknown>) => result.data as EcommerceSaleCreate | null | undefined),
        ),
    );

    return expenseType;
  }

  public async selectPaymentProcessor(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<EcommerceSaleCreate | null | undefined> {
    const { SelectPaymentProcessorModalComponent } = await import(
      '../modals/select-payment-processor-modal/select-payment-processor-modal.component'
    );

    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchAddCommissionButtonClicked(
      operation,
      this.salesChannel,
    );

    const paymentProcessor: EcommerceSaleCreate | null | undefined = await lastValueFrom(
      this.modalService
        .open(SelectPaymentProcessorModalComponent, {
          data: {
            sectionId: section.sectionId,
            subSectionId: subSection.subSectionId,
            operation,
          },
        })
        .afterClosed$.pipe(
          map((result: OverlayCloseEvent<unknown>) => result.data as EcommerceSaleCreate | null | undefined),
        ),
    );

    if (!paymentProcessor) {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchAddCommissionModalClosed(
        operation,
        this.salesChannel,
      );
    } else {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchCommissionSelected(
        paymentProcessor,
        operation,
        this.salesChannel,
      );
    }

    return paymentProcessor;
  }

  public async selectDestinationZoneForIoss(
    operation: Operation,
    section: EcommerceSaleSection,
    subSection: EcommerceSaleSubSection,
  ): Promise<EcommerceSaleCreate | null | undefined> {
    const { SelectDestinationZoneModalComponent } = await import(
      '../modals/select-destination-zone-modal/select-destination-zone-modal.component'
    );
    return await lastValueFrom(
      this.modalService
        .open(SelectDestinationZoneModalComponent, {
          data: {
            sectionId: section.sectionId,
            subSectionId: subSection.subSectionId,
            operation,
          },
        })
        .afterClosed$.pipe(
          map((result: OverlayCloseEvent<unknown>) => result.data as EcommerceSaleCreate | null | undefined),
        ),
    );
  }

  private async hasIoss(): Promise<boolean> {
    const companyHasIOSS: boolean = await lastValueFrom(
      this.companyStateService.activeCompany$.pipe(
        first(),
        map((company) => company.accountingConfiguration.hasIoss),
      ),
    );
    return companyHasIOSS;
  }

  public async isMarketplaceDispatch(operation: Operation): Promise<boolean> {
    const isMarketplace: boolean = await lastValueFrom(
      this.salesChannelState.salesChannels$.pipe(
        first(),
        map((salesChannels) =>
          salesChannels.some(
            (salesChannel) =>
              salesChannel.category === 'marketPlace' && operation?.metadata?.salesChannelId === salesChannel.id,
          ),
        ),
      ),
    );
    return isMarketplace;
  }

  public async hasIossAndIsMarketPlace(operation: Operation): Promise<boolean> {
    return (await this.isMarketplaceDispatch(operation)) && (await this.hasIoss());
  }

  public async openDeleteAllConfirmation(operation: Operation): Promise<void> {
    this.operationEcommerceMetricsService.trackEcommerceDispatchResetClicked(operation, this.salesChannel);
    const confirm: boolean | null | undefined = (
      await lastValueFrom(
        this.modalService.open<boolean>(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `Voulez-vous effacer vos ventes, commissions, frais divers et justificatifs de <span class="italic">${
              this.salesChannel?.name || 'cette ventilation'
            }</span> ? Cette action est définitive.`,
            yesText: 'Oui, effacer toute la ventilation',
            noText: 'Annuler',
          },
        }).afterClosed$,
      )
    ).data;

    if (confirm) {
      this.operationEcommerceMetricsService.trackEcommerceDispatchResetModalValidated(operation, this.salesChannel);
      this.isLoadingSubject.next(true);
      await this.abstractOperationsStateService.deleteDispatchOperationBreakdowns(operation.companyId, operation.id);
      await this.ecommerceSaleStateService.refreshEcommerceSales(operation);
      await this.abstractOperationsStateService.refreshOperationById(operation.companyId, operation.id);
      this.isLoadingSubject.next(false);
    } else {
      this.operationEcommerceMetricsService.trackEcommerceDispatchResetModalClosed(operation, this.salesChannel);
    }
  }

  onResourceLinkClick(operation: Operation): void {
    this.operationEcommerceMetricsService.trackEcommerceResourceClicked(operation, this.salesChannel);
  }
}
